export const GAME_WIDTH = 240;
export const GAME_HEIGHT = 160;

export const BLOCK_RENDER_SIDE_LEN = 20;

export const GRID_BLOCK_SIDE_LEN = 5;
export const GRID_BLOCK_CENTER = Math.floor(GRID_BLOCK_SIDE_LEN / 2);
export const GRID_RENDER_SIDE_LEN = GRID_BLOCK_SIDE_LEN * BLOCK_RENDER_SIDE_LEN;
export const GRID_RENDER_OFFSET_X = BLOCK_RENDER_SIDE_LEN;
export const GRID_RENDER_OFFSET_Y = BLOCK_RENDER_SIDE_LEN * 1.5;

export const INVENTORY_OFFSET_X =
  GRID_RENDER_OFFSET_X + GRID_RENDER_SIDE_LEN + BLOCK_RENDER_SIDE_LEN;
export const INVENTORY_OFFSET_Y = GRID_RENDER_OFFSET_Y;

export const COLORS = {
  // shared
  BOARD: "darkorange",
  BORDER: "#889EB3",
  // grid
  GRID_BACKGROUND: "#124D7F",
  GRID_CURSOR: "lime",
  GRID_CURSOR_LETTER: "#0006",
  LINE_SHADOW: "#00000033",
  // line
  PROCESSING_LETTER: "WHITE",
  CORRECT_LETTER: "lime",
  INCORRECT_LETTER: "red",
  INVALID_LETTER: "orange",
  // inventory
  INVENTORY_CURSOR_BORDER: "green",
  INVENTORY_CURSOR: "lime",
};
